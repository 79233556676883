import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import instructions from '../files/Simtrim-Basic-Installation-Instructions.pdf'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>What is SIMTRIM™</h1>
    <p>Introducing a system that is so simple in concept and so easy to install and maintain. It’s amazing that this type of system has never been marketed or utilized before now.   By using only the most basic of conventional home tools, you too can enjoy the benefits of the SIMTRIM™  Molding System.</p>
    <p>SIMTRIM™  is a system for transforming molding or trim to make it both removable and re-installable.  By simply installing and utilizing a dovetail groove in the back face of molding or trim, this patented and Trade Marked two part snap system will effectively hold molding or trim tightly against nearly any flat surface*. By following the simple directions found in this web site, it is easy to utilize the SIMTRIM™ Molding System.  The SIMTRIM™ Molding System basically consists of the following three main components:</p>
    <ol>
      <li>Conventional molding or trim (with an appropriate dovetail groove in the back face).</li>
      <li>Female SIMTRIM™ snap components.</li>
      <li>Male SIMTRIM™ snap components.</li>
    </ol>
    <p>SIMTRIM™ snap components are available simply by ordering them via the following email:</p>
    <p><a href='mailto:simtrim@telus.net'>simtrim@telus.net</a></p>
    <p>JUST SNAP ON AND SNAP OFF!</p> 
    <p>For a pictorial representation of how to install trim with the SIMTRIM™ system see the attached:</p>
    <p><a href={instructions}>Simtrim Basic Installation Instructions</a></p>
    <p className='footnote'>* any surface that accepts a wood or sheet metal screw</p>
  </Layout>
)

export default IndexPage
